import React from "react"
import { Link } from "gatsby"
import { motion } from 'framer-motion'
import Layout from "../components/layout"
import Hero from "../components/hero"
import Cta from "../components/cta"
import shaun from "../images/shaun-symm.png"
import dave from "../images/david-pretty.png"
import Collapsible from 'react-collapsible';
import Seo from "../components/seo"
import Twitter from "../components/svg/twitter"
import Linkedin from "../components/svg/linkedin"
import TestimonialSection from "../components/testimonials-section"
import ChevronDown from "../components/svg/chevronDown"
import ClientLogos from "../components/client-logos"

const AboutPage = () => (
    <Layout>
        <Seo 
            title="About" 
            description="Launch Lab is a web design and web development company in Sydney and Canberra, Australia. We're highly experienced onshore web developers."
            pathname="/about/"
        />

        <Hero
            h1="We are Launch Lab"  
            h2="An experienced web design, web and application development company in Sydney & Canberra"
        />

        <section className="bg-purple with-pad-sm">
            <div className="container">
                <div className="row">
                    <motion.div className="below-hero"
                        initial={{ opacity: 0, x: 120 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{ ease: "easeOut", delay: 0.25, duration: 0.75 }}
                    >
                        <div className="below-hero-widget">
                            <span className="widget-heading">2014</span>
                            <p>We launched in 2014 to help non-technical founders develop startups ... but, it's not just startups anymore.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">SYD / CAN</span>
                            <p>We are based in Sydney and Canberra but work with startups and companies around Australia.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">40+</span>
                            <p>We've developed 40+ startup MVPs and worked with acquired startups, large companies and government too.</p>
                        </div>
                    </motion.div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <motion.h3
                                initial={{ opacity: 0, y: 80 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ ease: "easeOut", duration: 0.5 }}
                            >Our story</motion.h3>
                        </div>

                        <motion.div 
                            className="col-67 long-text"
                            initial={{ opacity: 0, y: 80 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ ease: "easeOut", delay: 0.25, duration: 0.75 }}
                        >
                            <p>We started Launch Lab in 2014 to help non-technical startup founders get started.</p>
                            <p>In that time we've become one of the most experienced <Link to="/startup-web-application-development">startup developers</Link> in Australia helping guide founders with product design, strategy and product development.</p>
                            <p>It's not just startups though. We also work with larger organisations like Immutable, Green Building Council of Australia, Waddle (a Xero company), an established biotech company and more.</p>
                            <p>We're a senior, experienced, team with all of our development work completed onshore in Australia. We offer full stack <Link to="/services">web development services</Link> including:</p>
                            <ul>
                                <li>web design</li>
                                <li>web development</li>
                                <li>application development</li>
                                <li>AI and Machine Learning</li>
                                <li>content management systems, and</li>
                                <li>AWS development &amp; support</li>
                            </ul>
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>

        <div className="container">
            <div className="row">
            <div className="section-line"></div>
            </div>
        </div>

        <section className="section-pad">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <motion.div 
                            className="col-26"
                            initial={{ opacity: 0, y: 80 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ ease: "easeOut", duration: 0.5 }}
                        >
                            <h4>Founders</h4>
                            <p>A hands on web development team with owner driven developers</p>
                        </motion.div>

                        <motion.div 
                            className="col-67 long-text"
                            initial={{ opacity: 0, y: 80 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ ease: "easeOut", delay: 0.25, duration: 0.75 }}
                        >
                            <div className="founder top">
                                <div className="founder-img flex flex-ac">
                                    <img src={shaun} alt="Shaun Symm, Managing Director of Launch Lab" />
                                    <div className="founder-name">
                                        <h5>Shaun Symm</h5>
                                        <span>Managing Director</span>
                                    </div>
                                </div>
                                <p>Shaun sold his shares in an advertising agency in 2008 to focus on digital. He assists Launch Lab clients with strategy, project management, web design, frontend web development and <Link to="/webflow-developer/">Webflow websites</Link>.</p>
                                <p>Shaun has worked with more than 40 startups, Government as well as brands like Immutable, Telstra, Aussie Home Loans, Nurofen, Microsoft, ANU and Nissan.</p>
                                <p>Shaun holds 2 bachelor degrees (Sports Science and Marketing Management) and has completed courses in user experience design (UX), agile project management, web development, growth hacking and marketing strategy.</p>
                                <div className="flex founder-socials">
                                    <a href="https://twitter.com/shaunsymm" className="about-icon twitter-icon mr-1">
                                        <Twitter />
                                    </a>
                                    <a href="https://au.linkedin.com/in/shaunsymm" className="about-icon linkedin-icon">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>

                            <div className="founder" id="dave-pretty">
                                <div className="founder-img flex flex-ac">
                                    <img src={dave} alt="David Pretty, Chief Technical Officer (CTO) of Launch Lab" />
                                    <div className="founder-name">
                                        <h5>David Pretty</h5>
                                        <span>Chief Technical Officer</span>
                                    </div>
                                </div>
                                <p>David is a software engineer and web developer. David has a PhD in Data Science from Australian National University and has developed complex academic solutions through to software for startups and business web applications.</p>
                                <p>David has vast experience in data science and data visualisation and is an avid follower of web development and digital strategy podcasts.</p>
                                <p>Some of the technologies David works with include Python / Django, React.js, Vue.js, Tensorflow and Amazon Web Services (AWS).</p>
                                <div className="flex founder-socials">
                                    <a href="https://twitter.com/davidpretty" className="about-icon twitter-icon mr-1">
                                        <Twitter />
                                    </a>
                                    <a href="https://www.linkedin.com/in/david-pretty-7a7b051/" className="about-icon linkedin-icon">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>

        <div className="container">
            <div className="row">
            <div className="section-line"></div>
            </div>
        </div>

        <section className="section-pad">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h5>The company we keep</h5>
                            <p>From early stage and funded startups to large organisations and government, here are some of the clients we've worked with.</p>
                        </div>
                        <motion.div 
                            className="col-67 flex flex-sb flex-wrap"
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ ease: "easeOut", delay: 0.25, duration: 0.75 }}
                        >
                            <ClientLogos />
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section-pad bg-black">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h5 className="title">
                                FAQs
                            </h5>
                        </div>
                        <div className="col-67">
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    What type of websites do you develop? <ChevronDown />
                                </>
                                }
                            >
                                <p>We design and develop marketing websites, web applications and even chatbots.</p>
                                <p>The majority of the work we do is developing serverless web applications for startups using <Link to="/python-developer/">Python</Link>, GraphQL, AWS and <Link to="/react-developers-sydney-canberra/">React</Link>.</p>
                                <p>We also <Link to="/web-design/">design</Link> and develop websites and provide <Link to="/headless-cms/">headless CMS development</Link> together with <Link to="/gatsby-developer/">Gatsby</Link>.</p>
                                <p>While we use Django less these days we are experienced Django developers and continue to maintain projects built on the framework.</p>
                                <p>While we love writing our own code we now also offer <Link to="/webflow-developer/">Webflow website development</Link> to deliver powerful marketing websites rapidly.</p>
                            </Collapsible>
                            <Collapsible
                                transitionTime={100}
                                trigger={
                                <>
                                    What technologies do you use? <ChevronDown />
                                </>
                                }
                            >
                                <p>When developing startups and web applications the majority of the work we do makes use of Python, AWS (serverless), GraphQL and React.js.</p>
                                <p>We've also completed frontend projects using Vue.js and we're highly experienced Django developers too.</p>
                                <p>For marketing websites and CMS projects our preference is Gatsby and Contentful, or a low-code solution like Webflow for suitable projects.</p>
                                <p>For AI we leverage large language models and for machine learning we have experience using Python, PyTorch and Tensorflow.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    What does a website or application cost? <ChevronDown />
                                </>
                                }
                            >
                                <p>We don't have a price list as it really depends on the unique requirements of your project.</p>
                                <p>The best way to work with us is on a time and materials basis. We'll provide an estimate upfront but will track the project on timesheets. If you have well defined specifications and brief then we're happy to provide a fixed cost option.</p>
                                <p>If you are a startup founder, you might find our blog post <Link to="/cost-to-develop-australian-startup-2019/">what it costs to develop a startup</Link> helpful.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    How long does it take to develop a website or application? <ChevronDown />
                                </>
                                }
                            >
                                <p>It depends. Ok, so that's not the answer you wanted but keep in mind that all web and app development projects are different. To give you a better idea here are a few project examples and the usual time frames:</p>
                                <ul>
                                    <li>Landing page (sales page) development for a leading Australian brand: 1 - 2 weeks. Includes design, development and testing</li>
                                    <li>Brochure websites: these are websites where the functionality is simple page navigation and probably a contact form. Essentially a marketing website that doesn't require user logins. 4 - 6 weeks</li>
                                    <li>Business websites with CMS: 6 - 12 weeks</li>
                                    <li>Startups: Startups usually have user logins, payment gateways and other custom features. 10 weeks+</li>
                                    <li>App development: This really depends on features and whether the app requires a custom cloud backend and whether both iOS and Android apps are required. 12 weeks+</li>
                                </ul>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Do you take equity in the startups you work with? <ChevronDown />
                                </>
                                }
                            >
                                <p>Our preference is to only take equity in a startup if we have a deep understanding of the market (domain experience) and the problem that is being solved.</p> 
                                <p>However, we'll break this rule for something exceptional that gets us excited. Our domain experience includes digital marketing, advertising, web development, design, big data, data science and data visualisation which means if you are working in this space and have a promising idea there is a chance we'd want to discuss equity in return for lower development fees.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    What is your process? <ChevronDown />
                                </>
                                }
                            >
                                <p>There's nothing fancy here, but what you'll find is we stick to our process closely to deliver projects on time and on budget. That means a collaborative and iterative process that delivers great results. Our process includes:</p>
                                <ul>
                                    <li>Initial project scoping and cost estimate</li>
                                    <li>Functional specifications and wireframes are written and created</li>
                                    <li>Web design is done based on approved wireframes</li>
                                    <li>Web development and infrastructure setup is done based on approved functional specification. Our methodology is test driven development, where budget allows, ensuring a smoother UAT process</li>
                                    <li>User Acceptance Testing (UAT)</li>
                                    <li>Go live</li>
                                    <li>30 day bug fixing warranty</li>
                                    <li>Ongoing management (optional)</li>
                                </ul>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Where is the work done? <ChevronDown />
                                </>
                                }
                            >
                                <p>All of the development work we do is completed in Australia (mostly in Sydney, Canberra and Perth).</p>
                                <p>We are currently testing offshore development channels, with an onshore CTO, using our own money. We may offer an offshore option in future if budgets and timelines can be optimised and quality of work maintained. However, thus far we've found that cost vs quality means that keeping all development in Australia offers the best outcome for the projects we work on.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Can you help me find investors for my startup? <ChevronDown />
                                </>
                                }
                            >
                                <p>We can introduce you to <Link to="/startup-raise-investment-australia">investors</Link> that we have relationships with should your concept fit with their investment strategies.</p>
                                <p>We can also assist preparing pitch presentations and pitch training. While not experts, we are also knowledgeable on Jobs NSW startup Grants and can advise accordingly.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Do you only work with startups? <ChevronDown />
                                </>
                                }
                            >
                                <p>No. While we're probably one of the most experienced startup developers in the country we've also worked with City of Sydney, NSW Government, Australia National University, Waddle (a Xero Company), Aussie Home Loans and more.</p>
                            </Collapsible>
                        </div>
                    </div>
 
                </div>
            </div>
        </section>

        <TestimonialSection />

        <Cta
        text="If you're looking for a web design quote or a web development quote from a local, onshore, and highly experienced, Australian web design and development team, we'd love to discuss how we can help and collaborate on your project."  
        />

    </Layout>
)

export default AboutPage